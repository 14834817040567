import React from "react";

import { SeeUs } from "./homecomponents";

import "./ShowsPage.css";

import unionstage from "../utils/unionstage_052723.jpg";
import ninethirtyclub from "../utils/930_101423.jpg";
import dc9 from "../utils/DC9_010723.jpg";
import willleet from "../utils/DC PAWL F23 IG .png";

export default function ShowsPage(props) {
  return (
    <div className="page-wrapper">
      <div className="page-container">
        <SeeUs />

        <h1>Past Shows</h1>
        <div className="flex past-shows-grid">
          <img src={unionstage} className="show-poster _past" />
          <img src={dc9} className="show-poster _past" />
          <img src={ninethirtyclub} className="show-poster _past" />
          <img src={willleet} className="show-poster _past" />
        </div>
      </div>
    </div>
  );
}
