import React from "react";

import "../HomePage.css";

export default function TextUs(props) {
  return (
    <div className="home-section">
      <h1>Text Us</h1>
      <div className="text-us">
        Send us a text at +1 (844) 233 3979 to get the latest news from Strutman
        Lane!
      </div>
    </div>
  );
}
