import React from "react";

import "../HomePage.css";

export default function StreamUs(props) {
  return (
    <div className="home-section">
      <h1>Stream Us</h1>
      <iframe
        className="spotify-playlist"
        src="https://open.spotify.com/embed/playlist/2jJhw5rD4ES0OYxVo5wQrQ?utm_source=generator"
        width="100%"
        height="380"
        frameBorder=""
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );
}
