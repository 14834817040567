import React from "react";
import { Link } from "react-router-dom";

import "./Menu.css";

export default function Menu(props) {
  return (
    <div className="menu page-wrapper" onClick={props.close}>
      <div className="page-container">
        <Link to="/" className="menu-option">
          Home
        </Link>
        <Link to="/shows" className="menu-option">
          Shows
        </Link>
        <Link to="/about" className="menu-option">
          About
        </Link>
        <Link to="/contact" className="menu-option">
          Contact
        </Link>
      </div>
    </div>
  );
}
