import React from "react";
import { Link } from "react-router-dom";

import "../PressPage.css";

const press = [
  {
    link: "https://washingtoncitypaper.com/article/584427/funk-band-strutman-lane-begins-2023-with-a-sold-out-show-at-dc9/",
    org: "Washington City Paper",
    quote: "A rising sensation in the local music ecosystem.",
  },
  {
    link: "https://dbknews.com/2023/01/10/strutman-lane-funk-pop-band-dc-nightclub-concert/",
    org: "The Diamondback",
    quote: "A truly authentic and special performance.",
  },
];

export default function PressPreview(props) {
  return (
    <div className="home-section">
      {press.map((p) => (
        <div key={p.link} className="press-quote _preview">
          <h2>
            <q>{p.quote}</q>
          </h2>
          <h3>{p.org}</h3>
          <a target="_blank" rel="noopener" href={p.link}>
            {p.link}
          </a>
        </div>
      ))}
    </div>
  );
}
