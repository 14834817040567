import React from "react";

import crowd2 from "../utils/crowd1.jpg";

import "./AboutPage.css";

export default function AboutPage(props) {
  return (
    <div className="about-page">
      <div className="page-wrapper about">
        <div className="page-container">
          <img className="pic" src={crowd2} />

          <div className="splash">
            <div className="home-section bio">
              <h2>About Us</h2>
              <div>
                From the outskirts of DC, a group of young men with a mutual
                love of funk and pop came together to breathe sensuality into
                new original music. Starting with their 2021 debut single, “I
                Want You To Know”, Strutman Lane’s rhythm guitar hooks, warm
                vocals, and driving sax lines cater spiritual pocket grooves to
                the soul. As former jazz school classmates, each member
                continues to spend at least 30 minutes a day working modal
                interchange, so rest assured you’re getting your money’s worth{" "}
                <br />
                <br />
                For better or for worse, Strutman Lane will make you rethink
                your life choices.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-wrapper about">
        <div className="page-container">
          <h1>Meet The Band</h1>
          <div className="flex member-grid">
            <div className="band-member">
              <div className="headshot _adam" />
              <h3 className="member-name">Adam Landa</h3>
            </div>
            <div className="band-member">
              <div className="headshot _evan" />
              <h3 className="member-name">Evan Williamson</h3>
            </div>
            <div className="band-member">
              <div className="headshot _kennan" />
              <h3 className="member-name">Kennan Spencer</h3>
            </div>
            <div className="band-member">
              <div className="headshot _chris" />
              <h3 className="member-name">Chris Lawrence</h3>
            </div>
            <div className="band-member">
              <div className="headshot _jack" />
              <h3 className="member-name">Jack Gruber</h3>
            </div>
            <div className="band-member">
              <div className="headshot _henry" />
              <h3 className="member-name">Henry Godfrey</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
