import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { HomePage, ShowsPage, AboutPage, ContactPage } from "./pages";

import "./App.css";

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export default function App() {
  const location = useLocation();

  useEffect(() => {
    setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        }),
      100
    );
    const pathArray = location.pathname
      .split("/")
      .filter((a) => a)
      .map(capitalizeFirstLetter);
    document.title =
      "Strutman Lane - " + (pathArray.length ? pathArray.join(" - ") : "Home");
  }, [location]);

  return (
    <>
      <div className="background" />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/shows" element={<ShowsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/*" element={<NoMatch />} />
      </Routes>
      <Footer />
    </>
  );
}

function NoMatch() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", { replace: true });
  });
  return null;
}
