import React from "react";

import { SocialLinks } from "../misc";

import "./Footer.css";

export default function Footer(props) {
  return (
    <div className="footer-wrapper">
      <div className="footer page-container flex">
        <div className="footer-box flex">
          <SocialLinks />
        </div>
      </div>
    </div>
  );
}
