import React, { useState } from "react";
import { Link } from "react-router-dom";

import { SocialLinks } from "../misc";

import Menu from "./Menu";

import logo from "../../utils/logo.png";

import "./Header.css";

export default function Header(props) {
  const [open, setOpen] = useState(false);

  return (
    <div className="header-wrapper">
      {open && <Menu close={() => setOpen(false)} />}
      <div className="header page-container flex">
        <Link to="/">
          <img src={logo} />
        </Link>
        <div className="header-box flex">
          <Link to="/">
            <div className="header-name">Strutman Lane</div>
          </Link>
          <div className="flex header-links">
            <SocialLinks />
            <div className="menu-icon" onClick={() => setOpen(true)} />
          </div>
        </div>
      </div>
    </div>
  );
}
