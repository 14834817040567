import React from "react";

import "./SocialLinks.css";

export default function SocialLinks(props) {
  return (
    <>
      <a
        target="_blank"
        rel="noopener"
        data-testid="SocialIcon"
        href="https://tiktok.com/@strutman_lane_band"
        aria-label="Strutman Lane tiktok"
      >
        <div className="icon _tiktok" />
      </a>
      <a
        target="_blank"
        rel="noopener"
        data-testid="SocialIcon"
        href="https://open.spotify.com/artist/1NxkwuSt5Gw3OUEiU05WgR"
        aria-label="Strutman Lane spotify"
      >
        <div className="icon _spotify" />
      </a>
      <a
        target="_blank"
        rel="noopener"
        data-testid="SocialIcon"
        href="https://instagram.com/strutmanlane"
        aria-label="Strutman Lane instagram"
      >
        <div className="icon _instagram" />
      </a>
      <a
        target="_blank"
        rel="noopener"
        data-testid="SocialIcon"
        href="https://www.youtube.com/channel/UCSopYY2JcgVC1xRC2ac7JTA?app=desktop"
        aria-label="Strutman Lane youtube"
      >
        <div className="icon _youtube" />
      </a>
    </>
  );
}
