import React from "react";

import ninethirty from "../../utils/blackcat.png";

import "../HomePage.css";

export default function SeeUs(props) {
  return (
    <div className="home-section">
      <h1>Upcoming Shows</h1>
      <a href="https://www.etix.com/ticket/p/83747852/strutman-lane-single-releaserock-creek-kings-at-black-cat-washington-black-cat" target="_blank" rel="noopener">
        <img
          src={ninethirty}
          className="show-poster"
          style={{ maxWidth: "50%", marginLeft: "auto", marginRight: "auto" }}
        />
      </a>
      <div className="center">
        <div className="flex center">
          <h2>Black Cat</h2>
          <h3>Jan 20 - 8PM</h3>
        </div>
      </div>
      <a href="https://www.etix.com/ticket/p/83747852/strutman-lane-single-releaserock-creek-kings-at-black-cat-washington-black-cat" target="_blank" rel="noopener">
        <button>Tickets</button>
      </a>
    </div>
  );
}
