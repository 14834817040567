import React, { useEffect } from "react";

import "../HomePage.css";

export default function WatchUs(props) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="home-section">
      <h1>Watch Us</h1>
      <blockquote
        className="tiktok-embed"
        cite="https://www.tiktok.com/@strutman_lane_band/video/7150311610974883114"
        data-video-id="7150311610974883114"
        style={{ maxWidth: "max-content", minWidth: "325px" }}
      >
        {" "}
        <section>
          {" "}
          <a
            target="_blank"
            title="@strutman_lane_band"
            href="https://www.tiktok.com/@strutman_lane_band?refer=embed"
          >
            @strutman_lane_band
          </a>{" "}
          attach us to your car we’ll get u free food every time{" "}
          <a
            title="mccdonalds"
            target="_blank"
            href="https://www.tiktok.com/tag/mccdonalds?refer=embed"
          >
            #mccdonalds
          </a>{" "}
          <a
            title="bandonatruck"
            target="_blank"
            href="https://www.tiktok.com/tag/bandonatruck?refer=embed"
          >
            #bandonatruck
          </a>{" "}
          <a
            title="funkband"
            target="_blank"
            href="https://www.tiktok.com/tag/funkband?refer=embed"
          >
            #funkband
          </a>{" "}
          <a
            title="drivethrough"
            target="_blank"
            href="https://www.tiktok.com/tag/drivethrough?refer=embed"
          >
            #drivethrough
          </a>{" "}
          <a
            title="funkpop"
            target="_blank"
            href="https://www.tiktok.com/tag/funkpop?refer=embed"
          >
            #funkpop
          </a>{" "}
          <a
            title="imlovinit"
            target="_blank"
            href="https://www.tiktok.com/tag/imlovinit?refer=embed"
          >
            #imlovinit
          </a>{" "}
          <a
            title="freefood"
            target="_blank"
            href="https://www.tiktok.com/tag/freefood?refer=embed"
          >
            #freefood
          </a>{" "}
          <a
            title="livemusic"
            target="_blank"
            href="https://www.tiktok.com/tag/livemusic?refer=embed"
          >
            #livemusic
          </a>{" "}
          <a
            target="_blank"
            title="♬ S.I.M.P (Squirrels In My Pants) [From &#39;Phineas And Ferb&#39;] - Geek Music"
            href="https://www.tiktok.com/music/SIMP-Squirrels-In-My-Pants-7033436299269556225?refer=embed"
          >
            ♬ S.I.M.P (Squirrels In My Pants) [From &#39;Phineas And Ferb&#39;]
            - Geek Music
          </a>{" "}
        </section>{" "}
      </blockquote>{" "}
      <div className="yt-vid">
        <iframe
          src="https://www.youtube.com/embed/XJ7-zziaksc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
