import React from "react";
import { Link } from "react-router-dom";

import splash from "../../utils/splash.webp";

import "../HomePage.css";

export default function Splash(props) {
  return (
    <>
      <img src={splash} className="splash-pic" />

      <div className="home-section splash flex">
        <div className="splash-text">
          <h2>New Lavender Haze Cover!</h2>

          <a href="https://open.spotify.com/track/13hpAXFkVqLaLU7YZH8NvI?si=a849011f03ee4e2f" target="_blank" rel="noopener">
            <button>Stream Now</button>
          </a>
        </div>
      </div>
    </>
  );
}
