import React from "react";

import "./HomePage.css";

import {
  Splash,
  PressPreview,
  SeeUs,
  WatchUs,
  StreamUs,
  Media,
  TextUs,
} from "./homecomponents";

export default function HomePage(props) {
  return (
    <div className="page-wrapper _home">
      <div className="page-container">
        <Splash />
        <PressPreview />

        <StreamUs />
        <WatchUs />
        {/* <Media /> */}
        <TextUs />
      </div>
    </div>
  );
}
