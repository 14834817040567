import React from "react";

import { TextUs } from "./homecomponents";

import "./ContactPage.css";

export default function ContactPage(props) {
  return (
    <div className="page-wrapper contact">
      <div className="page-container">
        <div className="home-section">
          <h1>Email Us</h1>
          <div className="text-us">
            For booking or general inquiries email us at strutmanlane@gmail.com
          </div>
        </div>
        <TextUs />
      </div>
    </div>
  );
}
